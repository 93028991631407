import { AdminModalType } from "Components/admin-modal/admin-modal";
import { PcraLoginInfoModalType } from "Components/pcra-login-info-modal/peru-compra-login-info-modal";

export interface AdminModalOpened {
	modalType: AdminModalType;
}

export interface PcraLoginInfoModalOpened {
	modalType: PcraLoginInfoModalType;
}

export interface DocumentClicked {
	mouseEvent: MouseEvent;
}

export interface VariantsReady {
	variants: AmplitudeVariant[];
}

export interface Subscription {
	unsubscribe(): void;
}

export type EventHandler<T> = (value: T) => void;

const EMPTY_SUBSCRIPTION: Subscription = {
	unsubscribe: () => undefined,
};

export class PubSub<T> {
	private handlers: Map<object, EventHandler<T>> = new Map<object, EventHandler<T>>();

	public subscribe(handler: EventHandler<T>): Subscription {
		if (!handler) {
			return EMPTY_SUBSCRIPTION;
		}
		const handlerKey = Object.create(null);
		this.handlers.set(handlerKey, handler);
		return {
			unsubscribe: () => {
				if (this.handlers && handlerKey) {
					this.handlers.delete(handlerKey);
				}
			},
		};
	}

	public publish(value: T): void {
		if (this.handlers.size === 0) {
			return;
		}
		this.handlers.forEach((handler) => handler(value));
	}

	public clear(): void {
		this.handlers.clear();
		this.handlers = new Map<object, EventHandler<T>>();
	}
}

export const PUB_SUBS = {
	DocumentClicked: new PubSub<DocumentClicked>(),
	AdminModalOpened: new PubSub<AdminModalOpened>(),
	PcraLoginInfoModalOpened: new PubSub<PcraLoginInfoModalOpened>(),
	ChileCompraLoginInfoModalOpened: new PubSub<{}>(),
};
