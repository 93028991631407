// tslint:disable-next-line:max-line-length
export const emailRegex =
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const thousandSeparatorRegex = /\B(?=(\d{3})+(?!\d))/g;
export const MIN_PAX_NUMBER_FOR_GROUP_BOOKING = 10;
export const MIN_ADULT_AGE = 18;
export const MIN_TEEN_AGE = 14;
export const CLOCK_REGEX = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
export const VOUCHER_REGEX = /^[0-9]{17}$/;
export const PROMO_CODE_REGEX = /^([a-zA-Z0-9\u00C0-\u017F]){1,8}$/;
export const FLIGHT_SELECT_URL = "Flight/InternalSelect";
export const BOOKING_RETRIEVE_URL = "Booking/Retrieve";
export const API_STATION_URL = "/api/Stations";
export const API_TIMETABLE_URL = "/api/TimeTable";
export const API_TIMETABLE_URL_FROM_PARAM = "fromStation";
export const API_TIMETABLE_URL_TO_PARAM = "toStation";
export const FARECACHE_NAVITAIRE_SETTINGS_PATH = "/configurations";
export const OPEN_CLASS_NAME = "open";
export const MIN_SCREEN_WIDTH_FOR_TWO_MONTH_CALENDAR = 1024;
export const COMMON_DAYJS_FORMAT = "YYYY-MM-DD";
export const DATEPICKER_RANGE_MODE = "range";
export const DATEPICKER_SINGLE_MODE = "single";
export const DATEPICKER_MAX_DATE = "maxDate";
export const DATEPICKER_MIN_DATE = "minDate";
export const DATEPICKER_DISABLED_DATES = "disable";
export const DATEPICKER_MODE = "mode";
export const CULTURE_US = "en-us";
export const CULTURE_BR = "pt-br";

export const DEFAULT_CALENDAR_LOOKAHEAD_DAYS = 365;

export const COOKIES = {
	AMPLITUDE: "JS_ABTestDeviceId",
	ORG_NAME: "JS_Org",
	PERU_COMPRA_LOGIN: "JS_PcraLogin",
	ChileCompraLogin: "JS_ChileCompraLogin",
	TEALIUM: "JS_Tealium",
	USER_INFO: "JS_UserInfo",
	USER_NAME: "JS_Name",
	PREVIOUS_SEARCH: "JS_PreviousSearch",
};

export const LINKS = {
	DC: "https://jetsmart.com/cl/es/club_de_descuentos",
};

// COUNTRY CODES
export const AGENCY_PAYMENT_FOP_CODE = "AG";
export const ARGENTINA_COUNTRY_CODE = "AR";
export const BRASIL_COUNTRY_CODE = "BR";
export const CHILE_COUNTRY_CODE = "CL";
export const COLOMBIA_COUNTRY_CODE = "CO";
export const CREDIT_SHELL_PAYMENT_FOP_CODE = "CF";
export const ELAVON_PAYMENT_FOP_CODE = "EV";
export const ECUADOR_COUNTRY_CODE = "EC";
export const PARAGUAY_COUNTRY_CODE = "PY";
export const PERU_COUNTRY_CODE = "PE";
export const PERU_TRANSFER_FOP_CODE = "PT";
export const SAFETYPAY_PAYMENT_FOP_CODE = "ST";
export const TRANSBANK_PAYMENT_FOP_CODE = "FT";
export const URUGUAY_COUNTRY_CODE = "UY";
export const USA_COUNTRY_CODE = "US";

export const BRASIL_REAL_CODE = "BRL";
export const ARGENTINIAN_PESO_CODE = "ARS";
export const USA_DOLLAR_CODE = "USD";
export const PERUVIAN_SOL_CODE = "PEN";
export const PERUVIAN_SOL_CODE_ALTERNATIVE = "SOL";

export const GENERAL_CURRENCY_SIGN = "$";
export const BRASIL_REAL_CURRENCY_SIGN = "R$";
export const PERUVIAN_SOL_CURRENCY_SIGN = "S/";

export const ARGENTINIAN_CULTURE_CODE = "es-ar";
export const BOLIVIAN_CULTURE_CODE = "es-bo";
export const BRASILIAN_CULTURE_CODE = "pt-br";
export const CHILEAN_CULTURE_CODE = "es-cl";
export const COLOMBIAN_CULTURE_CODE = "es-co";
export const COSTA_RICAN_CULTURE_CODE = "es-cr";
export const ECUADORIAN_CULTURE_CODE = "es-ec";
export const GUATEMALAN_CULTURE_CODE = "es-gt";
export const HONDURAN_CULTURE_CODE = "es-hn";
export const MEXICAN_CULTURE_CODE = "es-mx";
export const NICARAGUAN_CULTURE_CODE = "es-ni";
export const PANAMANIAN_CULTURE_CODE = "es-pa";
export const PARAGUAYAN_CULTURE_CODE = "es-py";
export const PERUVIAN_CULTURE_CODE = "es-pe";
export const SALVADORAN_CULTURE_CODE = "es-sv";
export const URUGUAYAN_CULTURE_CODE = "es-uy";
export const USA_CULTURE_CODE = "en-us";
export const VENEZUELAN_CULTURE_CODE = "es-ve";

export const WebAnonymousRoleCode = "WWWA";

export const PROMO_CODE_URL_KEY = "pc";

export const CULTURES_WITH_SUNDAY_AS_START_OF_WEEK = ["en-us", "pt-br"];

export const OUTBOUND = 0;
export const INBOUND = 1;

interface WidgetUrls {
	carUrl: string;
	transferUrl: string;
}

export const WIDGETS = new Map<string, WidgetUrls>([
	[
		USA_CULTURE_CODE,
		{
			carUrl: "https://www.rentalcars.com/partners/integrations/stand-alone-app/?preflang=en&fts=true&enableReturnCheckbox=true&css=true&affiliateCode=jetsmart&adplat=SBEN",
			transferUrl: "https://cars.cartrawler.com/jetsmart/en/search-gt?c=ie#/search?client=874601",
		},
	],
	[
		BRASILIAN_CULTURE_CODE,
		{
			carUrl: "https://www.rentalcars.com/partners/integrations/stand-alone-app/?preflang=br&fts=true&enableReturnCheckbox=true&css=true&affiliateCode=jetsmart&adplat=hp_panel&adcamp=standalone",
			transferUrl: "https://cars.cartrawler.com/jetsmart/pt/search-gt?c=ie#/search?client=874601&lang=PT",
		},
	],
	[
		ARGENTINIAN_CULTURE_CODE,
		{
			carUrl: "https://www.rentalcars.com/partners/integrations/stand-alone-app/?preflang=es&fts=true&enableReturnCheckbox=true&css=true&affiliateCode=jetsmart&adplat=SBAR",
			transferUrl: "",
		},
	],
	[
		PERUVIAN_CULTURE_CODE,
		{
			carUrl: "https://www.rentalcars.com/partners/integrations/stand-alone-app/?preflang=es&fts=true&enableReturnCheckbox=true&css=true&affiliateCode=jetsmart&adplat=SBPE",
			transferUrl: "",
		},
	],
	[
		"default",
		{
			carUrl: "https://www.rentalcars.com/partners/integrations/stand-alone-app/?preflang=es&fts=true&enableReturnCheckbox=true&css=true&affiliateCode=jetsmart&adplat=SBCH",
			transferUrl: "https://cars.cartrawler.com/jetsmart/es/search-gt?c=ie#/search?client=874601",
		},
	],
]);
